<template>
  <form class="rcn-main">
    <v-col v-if="nonet">
      <v-row>
        <v-col cols="12" class="d-flex justify-center text-center pt-10">
          <v-img
            lazy-src="../assets/nonet.png"
            width="200"
            max-width="250"
            src="../assets/nonet.png"
            cols="12"
          ></v-img>
        </v-col>
        <v-col cols="12" class="justify-center text-center">
          <h4 cols="12">Connect to the internet</h4>
          <p>You're offline. Check your connection</p>
          <v-btn text color="primary" @click="$router.go()"> Retry </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else>
      <h2 class="primary--text px-4 px-md-0">New Running log</h2>
      <v-stepper v-model="runningChartStep" vertical class="steper-x mt-md-3">
        <v-stepper-step :complete="runningChartStep > 1" step="1">
          Select the Vehicle
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-row class="py-3">
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete
                v-model="vehicle_no"
                :items="vehicles"
                item-text="vehicle_no"
                item-value="id"
                dense
                filled
                label="Vehicle No"
                :error-messages="vehicleNoErrors"
                @change="vehicleSelect"
                @blur="$v.vehicle_no.$touch()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-dialog
                ref="date_dialog"
                v-model="date_modal"
                :return-value.sync="date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Date"
                    append-icon="mdi-calendar"
                    dense
                    filled
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :error-messages="dateErrors"
                    @change="$v.date.$touch()"
                    @blur="$v.date.$touch()"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="date_modal = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.date_dialog.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-dialog
                ref="time_out_dialog"
                v-model="time_out_modal"
                :return-value.sync="time_out"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="time_out"
                    label="Time Out"
                    append-icon="mdi-clock-time-four-outline"
                    readonly
                    dense
                    filled
                    v-bind="attrs"
                    v-on="on"
                    :error-messages="timeOutErrors"
                    @change="$v.time_out.$touch()"
                    @blur="$v.time_out.$touch()"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="time_out_modal"
                  v-model="time_out"
                  full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="time_out_modal = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.time_out_dialog.save(time_out)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="meter_out"
                dense
                filled
                type="number"
                label="Meter Reading Out (Km)"
                :error-messages="meterOutErrors"
                @change="$v.meter_out.$touch()"
                @blur="$v.meter_out.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn color="primary" @click="continueOne"> Continue </v-btn>
          <v-btn text @click="save"> Save </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="runningChartStep > 2" step="2">
          Take a photo of your meter
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-row class="py-3">
            <v-col>
              <v-file-input
                v-model="image"
                label="File input"
                prepend-icon="mdi-camera"
                dense
                filled
                :error-messages="imageErrors"
                accept="image/*"
                @change="previewImage"
              ></v-file-input>
              <template v-if="preview">
                <img
                  :src="preview"
                  style="width: 100%; height: auto; max-width: 500px"
                />
              </template>
            </v-col>
          </v-row>

          <v-btn color="primary" @click="continnueTwo"> Continue </v-btn>
          <v-btn text @click="runningChartStep = 1"> Back </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="runningChartStep > 3" step="3">
          Fill the Basic Information
        </v-stepper-step>

        <v-stepper-content step="3">
          <v-row class="py-3">
            <v-col cols="12" md="6" class="py-0">
              <v-dialog
                ref="time_in_dialog"
                v-model="time_in_modal"
                :return-value.sync="time_in"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="time_in"
                    label="Time In"
                    append-icon="mdi-clock-time-four-outline"
                    readonly
                    dense
                    filled
                    v-bind="attrs"
                    v-on="on"
                    :error-messages="timeInErrors"
                    @change="$v.time_in.$touch()"
                    @blur="$v.time_in.$touch()"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="time_in_modal"
                  v-model="time_in"
                  full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="time_in_modal = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.time_in_dialog.save(time_in)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="meter_in"
                dense
                filled
                type="number"
                label="Meter Reading In (Km)"
                :error-messages="meterInErrors"
                @change="$v.meter_in.$touch()"
                @blur="$v.meter_in.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="no_of_km"
                dense
                filled
                label="No.of Kilometers"
                readonly
                dark
                background-color="secondary"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete
                v-model="peroject_code"
                :items="projects"
                item-text="project_code"
                item-value="id"
                dense
                filled
                label="Project Code"
                :error-messages="codeErrors"
                @change="$v.peroject_code.$touch()"
                @blur="$v.peroject_code.$touch()"
              ></v-autocomplete>
            </v-col>
            <!-- <v-col cols="12" md="6" class="py-0">
            <v-autocomplete
              v-model="department"
              :items="departments"
              dense
              filled
              label="Department"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-text-field
              v-model="user"
              dense
              filled
              label="User"
              readonly
            ></v-text-field>
          </v-col> -->
            <v-textarea
              class="px-3"
              v-model="description"
              filled
              label="Full description of places visited and any other places"
              auto-grow
              :error-messages="descriptionErrors"
              @change="$v.description.$touch()"
              @blur="$v.description.$touch()"
            ></v-textarea>
          </v-row>
          <v-btn color="primary" @click="continueThree"> Continue </v-btn>
          <v-btn text @click="runningChartStep = 2"> Back </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="runningChartStep > 4" step="4">
          Daily Check List
        </v-stepper-step>

        <v-stepper-content step="4">
          <v-row class="py-4">
            <v-col
              v-for="item in daily_check_items"
              :key="item.name"
              cols="12"
              md="6"
              lg="4"
              class="py-0"
            >
              <v-checkbox
                v-model="check_list"
                :value="item.id"
                :label="item.name"
                class="mx-3 my-0"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" class="pt-0 mx-6">
              <v-row align="center">
                <v-checkbox
                  v-model="if_defects"
                  hide-details
                  class="shrink mr-2 mt-0"
                ></v-checkbox>
                <v-text-field
                  :disabled="!if_defects"
                  v-model="defects"
                  label="Defects If Any"
                ></v-text-field>
              </v-row>
            </v-col>
            <v-col cols="12" class="pt-0 mx-6">
              <v-row align="center">
                <v-checkbox
                  v-model="if_others"
                  hide-details
                  class="shrink mr-2 mt-0"
                ></v-checkbox>
                <v-text-field
                  v-model="others"
                  :disabled="!if_others"
                  label="Others"
                ></v-text-field>
              </v-row>
            </v-col>
          </v-row>
          <v-btn color="primary" @click="runningChartStep = 5">
            Continue
          </v-btn>
          <v-btn text @click="runningChartStep = 3"> Back </v-btn>
        </v-stepper-content>

        <v-stepper-step step="5"> Fuel Information </v-stepper-step>

        <v-stepper-content step="5">
          <v-row class="pb-3">
            <v-col cols="12" class="py-0 d-flex">
              <v-checkbox
                label="Diesel"
                v-model="diesel"
                class="mx-3"
              ></v-checkbox>
              <v-checkbox
                label="Petrol"
                v-model="petrol"
                class="mx-3"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="no_of_litres_petrol"
                :disabled="!fuel_enabled"
                dense
                filled
                label="No. of Litres"
                type="number"
                :error-messages="petrolErrors"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="meter_readin_present"
                :disabled="!fuel_enabled"
                dense
                filled
                label="Meter Reading (Present)"
                type="number"
                :error-messages="meterReadInPresentErrors"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="meter_readin_last"
                :disabled="!fuel_enabled"
                dense
                filled
                label="Meter Reading at last filling"
                type="number"
                :error-messages="meterReadInLastErrors"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="no_of_km_last"
                :disabled="!fuel_enabled"
                readonly
                dark
                background-color="secondary"
                dense
                filled
                label="No. of KMS since last filling"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="kms_per_liter"
                :disabled="!fuel_enabled"
                dense
                filled
                readonly
                dark
                background-color="secondary"
                label="KMS per Liter"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="fuel_amount"
                :disabled="!fuel_enabled"
                dense
                filled
                label="Fuel Amount"
                type="number"
                :error-messages="fuelAmtErrors"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <v-row class="py-4">
            <v-col cols="12" class="pb-0">
              <v-checkbox v-model="oil" label="Oil" class="mx-3"></v-checkbox>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="no_of_litres_oil"
                :disabled="!oil"
                dense
                filled
                label="No. of Litres"
                type="number"
                :error-messages="oilErrors"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="meter_readind_present_oil"
                :disabled="!oil"
                dense
                filled
                label="Meter Reading (Present)"
                type="number"
                :error-messages="meterReadInPresentOilErrors"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="oil_amount"
                :disabled="!oil"
                dense
                filled
                label="Oil Amount"
                type="number"
                :error-messages="oilAmtErrors"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-dialog v-model="confirm_dialog" persistent max-width="290">
            <v-card>
              <v-card-title class="text-h5"> Confirmation </v-card-title>
              <v-card-text
                >Please take a minute and check all the information before
                submit. &#8987;</v-card-text
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary lighten-1" text @click="checkAgain">
                  Check again
                </v-btn>
                <v-btn
                  color="primary darken-1"
                  @click="submit"
                  :loading="submit_loading"
                  :disabled="submit_loading"
                >
                  Submit
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn color="primary" @click="confirm"> Submit </v-btn>
          <v-btn text @click="runningChartStep = 4"> Back </v-btn>
        </v-stepper-content>
      </v-stepper>
    </v-col>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </form>
</template>

<script>
const axios = require("axios").default;
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import compress from "compress-base64";

export default {
  name: "Dashboard",
  mixins: [validationMixin],
  validations: {
    vehicle_no: { required },
    date: { required },
    time_in: { required },
    time_out: { required },
    meter_in: { required },
    meter_out: { required },
    peroject_code: { required },
    description: { required },
    preview: { required },
    image: { required },
  },
  data: () => ({
    nonet: false,
    vehicle_no: null,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date_modal: false,
    preview: null,
    image: null,
    time_out: new Date().getHours() + ":" + new Date().getMinutes(),
    time_out_modal: false,
    time_in: new Date().getHours() + ":" + new Date().getMinutes(),
    time_in_modal: false,
    meter_in: null,
    meter_out: null,
    runningChartStep: 1,
    peroject_code: null,
    description: null,
    vehicles: [],
    projects: [],
    daily_check_items: [],
    check_list: [],
    includeFiles: true,
    if_defects: false,
    defects: null,
    if_others: false,
    others: null,
    fuel: null,
    diesel: false,
    petrol: false,
    fuel_enabled: false,
    meter_readin_present: null,
    meter_readin_last: null,
    no_of_litres_petrol: null,
    fuel_amount: null,
    oil: false,
    no_of_litres_oil: null,
    oil_amount: null,
    meter_readind_present_oil: null,
    confirm_dialog: false,
    loader: null,
    submit_loading: false,

    snackbar: false,
    text: ``,
    timeout: 3000,
  }),
  computed: {
    kms_per_liter() {
      if (this.no_of_km_last && this.no_of_litres_petrol) {
        return (this.no_of_km_last / this.no_of_litres_petrol).toFixed(2);
      } else {
        return 0;
      }
    },
    no_of_km() {
      if (this.meter_out && this.meter_in) {
        return this.meter_in - this.meter_out;
      } else {
        return 0;
      }
    },
    no_of_km_last() {
      if (this.meter_readin_present && this.meter_readin_last) {
        return this.meter_readin_present - this.meter_readin_last;
      } else {
        return 0;
      }
    },
    vehicleNoErrors() {
      const errors = [];
      if (!this.$v.vehicle_no.$dirty) return errors;
      !this.$v.vehicle_no.required && errors.push("Vehicle No is required");
      return errors;
    },
    dateErrors() {
      const errors = [];
      if (!this.$v.date.$dirty) return errors;
      !this.$v.date.required && errors.push("Date is required");
      new Date(this.date).getTime() > new Date(Date.now()).getTime() &&
        errors.push("Date must be less than or equal to current date");
      new Date(this.date) <
        new Date(new Date().setDate(new Date().getDate() - 2)) &&
        errors.push("Date must be yesterday or today");
      return errors;
    },
    imageErrors() {
      const errors = [];
      if (!this.$v.preview.$dirty) return errors;
      !this.$v.preview.required && errors.push("Image is required");
      return errors;
    },
    timeInErrors() {
      const errors = [];
      if (!this.$v.time_in.$dirty) return errors;
      !this.$v.time_in.required && errors.push("Time In is required.");
      return errors;
    },
    timeOutErrors() {
      const errors = [];
      if (!this.$v.time_out.$dirty) return errors;
      !this.$v.time_out.required && errors.push("Time Out is required.");
      return errors;
    },
    meterOutErrors() {
      const errors = [];
      if (!this.$v.meter_out.$dirty) return errors;
      !this.$v.meter_out.required &&
        errors.push("Meter Reading Out is required.");
      isNaN(this.meter_out) &&
        errors.push("Meter Reading Out must be numeric.");
      parseFloat(this.meter_out) > parseFloat(this.meter_in) &&
        errors.push("Meter Reading Out must be less than Meter Reading In.");
      return errors;
    },
    meterInErrors() {
      const errors = [];
      if (!this.$v.meter_in.$dirty) return errors;
      !this.$v.meter_in.required &&
        errors.push("Meter Reading In is required.");
      isNaN(this.meter_in) && errors.push("Meter Reading Out must be numeric.");
      parseFloat(this.meter_out) > parseFloat(this.meter_in) &&
        errors.push(
          "Meter Reading In must be greater than Meter Reading Out " +
            this.meter_out
        );
      return errors;
    },
    codeErrors() {
      const errors = [];
      if (!this.$v.peroject_code.$dirty) return errors;
      !this.$v.peroject_code.required &&
        errors.push("Project Code is required");
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      !this.$v.description.required &&
        errors.push("Full Description is required");
      return errors;
    },
    petrolErrors() {
      const errors = [];
      if (this.petrol || this.diesel) {
        !this.no_of_litres_petrol && errors.push("No of Litres is required");
        isNaN(this.no_of_litres_petrol) &&
          errors.push("No of Litres must be numeric.");
      }
      return errors;
    },
    meterReadInPresentErrors() {
      const errors = [];
      if (this.petrol || this.diesel) {
        !this.meter_readin_present &&
          errors.push("Meter Reading (Present) is required");
        isNaN(this.meter_readin_present) &&
          errors.push("Meter Reading must be numeric.");
        parseFloat(this.meter_readin_present) <
          parseFloat(this.meter_readin_last) &&
          errors.push(
            "Meter Reading (Present) must be greater than Last Filling Meter Reading."
          );
      }
      return errors;
    },
    meterReadInLastErrors() {
      const errors = [];
      if (this.petrol || this.diesel) {
        !this.meter_readin_last &&
          errors.push("Meter Reading at last filling is required");
        isNaN(this.meter_readin_last) &&
          errors.push("Meter Reading must be numeric.");
        parseFloat(this.meter_readin_present) <
          parseFloat(this.meter_readin_last) &&
          errors.push(
            "Last Filling Meter must be less than Reading Meter Reading (Present)."
          );
      }
      return errors;
    },
    fuelAmtErrors() {
      const errors = [];
      if (this.petrol || this.diesel) {
        !this.fuel_amount && errors.push("Fuel amout is required");
        isNaN(this.fuel_amount) && errors.push("Amount must be numeric.");
      }
      return errors;
    },
    oilAmtErrors() {
      const errors = [];
      if (this.oil) {
        !this.oil_amount && errors.push("Oil amout is required");
        isNaN(this.oil_amount) && errors.push("Amount must be numeric.");
      }
      return errors;
    },
    oilErrors() {
      const errors = [];
      if (this.oil) {
        !this.no_of_litres_oil && errors.push("No of Litres is required");
        isNaN(this.no_of_litres_oil) &&
          errors.push("No of Litres must be numeric.");
      }
      return errors;
    },
    meterReadInPresentOilErrors() {
      const errors = [];
      if (this.oil) {
        !this.meter_readind_present_oil &&
          errors.push("Meter Reading (Present) is required");
        isNaN(this.meter_readind_present_oil) &&
          errors.push("Meter Reading must be numeric.");
        parseFloat(this.meter_readind_present_oil) <
          parseFloat(this.meter_out) &&
          errors.push(
            "Meter Reading must greater than meter reading out" + this.meter_out
          );
        parseFloat(this.meter_readind_present_oil) >
          parseFloat(this.meter_in) &&
          errors.push(
            "Meter Reading must greater than meter reading in" + this.meter_in
          );
      }
      return errors;
    },
  },
  watch: {
    diesel(val) {
      if (val) {
        this.petrol = false;
        this.fuel_enabled = true;
        this.fuel = "diesel";
      } else {
        if (!this.petrol) {
          this.fuel_enabled = false;
          this.fuel = null;
        }
      }
    },
    petrol(val) {
      if (val) {
        this.diesel = false;
        this.fuel_enabled = true;
        this.fuel = "petrol";
      } else {
        if (!this.diesel) {
          this.fuel_enabled = false;
          this.fuel = null;
        }
      }
    },
  },
  methods: {
    vehicleSelect() {
      this.$v.vehicle_no.$touch();
      axios
        .get(this.$backend_url + "vehicle-info", {
          params: {
            remember_token: JSON.parse(localStorage.imso_user)[
              "remember_token"
            ],
            user: JSON.parse(localStorage.imso_user)["email"],
            vehicle_id: this.vehicle_no,
          },
        })
        .then((response) => {
          if (response.data.success) {
            this.meter_out = response.data.vehicle_info.meter_in;
            this.meter_readin_last =
              response.data.vehicle_info.fuel_meter_present;
          }
          this.nonet = false;
        })
        .catch((response) => {
          console.log("Errors:", response);
          this.nonet = true;
          this.snackbar = true;
          this.text = `No Internet`;
        });
    },
    previewImage() {
      let reader = new FileReader();
      reader.readAsDataURL(this.image);
      reader.onload = () => {
        let img = reader.result;
        let type = img.substring("data:image/".length, img.indexOf(";base64"));
        compress(reader.result, {
          width: 400,
          type: "image/" + type, // default
          max: 200, // max size
          min: 20, // min size
          quality: 0.8,
        }).then((result) => {
          this.preview = result;
        });
      };
      this.$v.preview.$touch();
    },
    continueOne() {
      if (
        this.vehicle_no &&
        this.date &&
        this.time_out &&
        this.meter_out &&
        !isNaN(this.meter_out) &&
        new Date(this.date).getTime() <= new Date(Date.now()).getTime()
      ) {
        this.runningChartStep = 2;
      } else {
        this.$v.date.$touch();
        this.$v.vehicle_no.$touch();
        this.$v.time_out.$touch();
        this.$v.meter_out.$touch();
      }
    },
    continnueTwo() {
      if (this.preview) {
        this.runningChartStep = 3;
      } else {
        this.$v.preview.$touch();
      }
    },
    continueThree() {
      if (
        this.time_in &&
        this.meter_in &&
        !isNaN(this.meter_in) &&
        parseFloat(this.meter_out) <= parseFloat(this.meter_in) &&
        this.peroject_code &&
        this.description
      ) {
        this.runningChartStep = 4;
      } else {
        this.$v.time_in.$touch();
        this.$v.meter_in.$touch();
        this.$v.peroject_code.$touch();
        this.$v.description.$touch();
      }
    },
    confirm() {
      if (this.petrol || this.diesel || this.oil) {
        if (this.petrol || this.diesel) {
          if (
            this.no_of_litres_petrol &&
            this.meter_readin_present &&
            this.meter_readin_last &&
            this.kms_per_liter
          ) {
            if (this.oil) {
              if (
                this.no_of_litres_oil &&
                this.meter_readind_present_oil &&
                parseFloat(this.meter_readind_present_oil) <
                  parseFloat(this.meter_in) &&
                parseFloat(this.meter_readind_present_oil) >
                  parseFloat(this.meter_out)
              ) {
                this.confirm_dialog = true;
              }
            } else {
              this.confirm_dialog = true;
            }
          }
        } else {
          if (
            this.no_of_litres_oil &&
            this.meter_readind_present_oil &&
            parseFloat(this.meter_readind_present_oil) <
              parseFloat(this.meter_in) &&
            parseFloat(this.meter_readind_present_oil) >
              parseFloat(this.meter_out)
          ) {
            this.confirm_dialog = true;
          }
        }
      } else {
        this.confirm_dialog = true;
      }
    },
    submit() {
      const l = "submit_loading"; // button loader
      this[l] = !this[l]; // button loader

      axios
        .post(this.$backend_url + "running-log", {
          vehicle_id: this.vehicle_no,
          date: this.date,
          time_in: this.time_in,
          time_out: this.time_out,
          meter_in: this.meter_in,
          meter_out: this.meter_out,
          no_of_km: this.no_of_km,
          description: this.description,
          peroject_id: this.peroject_code,
          daily_chek_list_ids: this.check_list,
          defets_if_any: this.if_defects ? this.defects : null,
          other_checks: this.if_others ? this.others : null,
          is_fuel: this.fuel_enabled,
          fuel: this.fuel,
          fuel_no_of_liters: this.no_of_litres_petrol,
          fuel_meter_present: this.meter_readin_present,
          fuel_meter_last: this.meter_readin_last,
          fuel_no_of_kms: this.no_of_km_last,
          fuel_kms_per_liter: this.kms_per_liter,
          fuel_amount: this.fuel_amount,
          is_oil: this.oil,
          oil_no_of_liters: this.no_of_litres_oil,
          oil_meter_reading: this.meter_readind_present_oil,
          remember_token: JSON.parse(localStorage.imso_user)["remember_token"],
          oil_amount: this.oil_amount,
          email: JSON.parse(localStorage.imso_user)["email"],
          image: this.preview,
        })
        .then((response) => {
          if (localStorage.imso_running_log_temp) {
            localStorage.removeItem("imso_running_log_temp");
          }

          this[l] = false; // button loader
          if (response.data["success"]) {
            this.$router.push("/running-log/history");
          }
        })
        .catch((res) => {
          console.log("Errors:", res);
          this.nonet = true;
          this.snackbar = true;
          this.text = `Error occured while sending data.`;
        });

      // this.confirm_dialog = false;
    },
    checkAgain() {
      this.runningChartStep = 1;
      this.confirm_dialog = false;
    },
    clear() {
      this.$v.$reset();
      this.vehicle_no = null;
      this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
      this.date_modal = false;
      this.time_out = null;
      this.time_out_modal = false;
      this.time_in = null;
      this.time_in_modal = false;
      this.meter_in = null;
      this.meter_out = null;
      this.runningChartStep = 1;
      this.peroject_code = null;
      this.description = null;
      this.check_list = [];
      this.if_defects = false;
      this.defects = null;
      this.if_others = false;
      this.others = null;
      this.fuel = null;
      this.diesel = false;
      this.petrol = false;
      this.fuel_enabled = false;
      this.meter_readin_present = null;
      this.meter_readin_last = null;
      this.no_of_litres_petrol = null;
      this.kms_per_liter = null;
      this.fuel_amount = null;
      this.oil = false;
      this.oil_amount = null;
      this.no_of_litres_oil = null;
      this.meter_readind_present_oil = null;
      this.confirm_dialog = false;
      this.loader = null;
      this.submit_loading = false;
      this.runningChartStep = 1;
    },
    save() {
      if (
        this.vehicle_no &&
        this.date &&
        this.time_out &&
        this.meter_out &&
        !isNaN(this.meter_out) &&
        new Date(this.date).getTime() <= new Date(Date.now()).getTime()
      ) {
        localStorage.imso_running_log_temp = JSON.stringify({
          time_out: this.time_out,
          vehicle_no: this.vehicle_no,
          meter_out: this.meter_out,
          date: this.date,
        });

        this.snackbar = true;
        this.text = `Information saved!`;
      } else {
        this.$v.date.$touch();
        this.$v.vehicle_no.$touch();
        this.$v.time_out.$touch();
        this.$v.meter_out.$touch();
      }
    },
  },
  mounted() {
    if (localStorage.imso_running_log_temp) {
      this.time_out = JSON.parse(localStorage.imso_running_log_temp)[
        "time_out"
      ];
      this.vehicle_no = JSON.parse(localStorage.imso_running_log_temp)[
        "vehicle_no"
      ];
      this.meter_out = JSON.parse(localStorage.imso_running_log_temp)[
        "meter_out"
      ];
      this.date = JSON.parse(localStorage.imso_running_log_temp)["date"];
    }
    axios
      .get(this.$backend_url + "initialize-log-form", {
        params: {
          remember_token: JSON.parse(localStorage.imso_user)["remember_token"],
          email: JSON.parse(localStorage.imso_user)["email"],
        },
      })
      .then((response) => {
        if (response.data.success) {
          this.daily_check_items = response.data.daily_check_list;
          this.vehicles = response.data.vehicles;
          this.projects = response.data.projects;
          if (!localStorage.imso_running_log_temp) {
            this.vehicle_no = response.data.vehicle_id;
            console.log(this.vehicle_no);
            this.vehicleSelect();
          }
        }
        this.nonet = false;
      })
      .catch((res) => {
        console.log("Errors:", res);
        this.nonet = true;
        this.snackbar = true;
        this.text = `No Internet`;
      });
  },
};
</script>

<style>
.rcn-main {
  padding: 20px;
  margin-left: 50px;
  width: calc(100vw - 60px);
  min-height: calc(100vh - 200px);
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@media only screen and (max-width: 960px) {
  .rcn-main {
    padding-right: 0px;
  }
}
@media only screen and (max-width: 600px) {
  .steper-x {
    box-shadow: none !important;
  }
  .rcn-main {
    padding: 20px;
    margin-left: 0px;
    width: 100vw;
  }
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>